import React from 'react'
import $ from 'jquery'

function Tabs(props) {
    function selectTab(event){
        $(".mapping-tab").removeClass("active")
        $(event.target).addClass("active")
        props.getActiveTab(event.target.name)
    }
    return (
        <div className='col-md-3 col-xl-3'>
            <div className='card mndesc'>
                <div className='card-body mapping-tab-sec'>
                    <div className='nav flex-column'>
                        <ul>
                            {
                                props.instance.isShippingMethodMappingEnable ? 
                                <li>
                                    <a name="ShippingMapping" className={props.instance.isShippingMethodMappingEnable && props.instance.isPaymentMethodMappingEnable ? 'nav-link mapping-tab active' : "nav-link mapping-tab"  } onClick={selectTab}> Shipping Method </a>
                                </li> : null
                            }
                            {
                                props.instance.isPaymentMethodMappingEnable ? 
                                <li>
                                    <a name="PaymentMethod" className={!props.instance.isShippingMethodMappingEnable && props.instance.isPaymentMethodMappingEnable ? 'nav-link mapping-tab active' :"nav-link mapping-tab"  } onClick={selectTab}> Payment Method </a>
                                </li>:null
                            }
                            {
                                props.instance.isMultiWarehouseAttributeMappingEnable ? 
                                <li>
                                    <a name="MultiLocation" className={!props.instance.isShippingMethodMappingEnable && !props.instance.isPaymentMethodMappingEnable ? 'nav-link mapping-tab active' :"nav-link mapping-tab"  } onClick={selectTab}> Multi Location </a> 
                                </li>:null
                            }
                            {
                                props.instance.isProductAttributeMappingEnable ?
                                <li>
                                    <a name="ProductAttributeMapping" className={!props.instance.isShippingMethodMappingEnable && !props.instance.isPaymentMethodMappingEnable && !props.instance.isMultiWarehouseAttributeMappingEnable ? 'nav-link mapping-tab active' :"nav-link mapping-tab"} onClick={selectTab}> Product Attribute </a> 
                                </li> : null
                            }
                            
                        </ul>
                    </div>
                </div>                    
            </div>
        </div>
    )
}

export default Tabs